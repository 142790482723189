import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { BsGithub } from "react-icons/bs";
import { FaEye } from "react-icons/fa";

function MobileProjectCards(props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Card className="project-card-view h-100">
        <Card.Img
          variant="top"
          src={props.imgPath}
          alt="card-img"
          style={{
            height: "200px",
            objectFit: "cover",
          }}
        />
        <Card.Body className="d-flex flex-column justify-content-between">
          <div>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text style={{ textAlign: "justify" }}>{props.description}</Card.Text>
          </div>

          {/* Button Container */}
          <div className="mt-auto">
            {props.ghLink && (
              <Button variant="primary" href={props.ghLink} target="_blank">
                <BsGithub /> &nbsp;
                {props.isBlog ? "Blog" : "GitHub"}
              </Button>
            )}

            {/* View Button - Opens Modal */}
            {!props.isBlog && props.demoImages && props.demoImages.length > 0 && (
              <Button
                variant="primary"
                onClick={() => setShow(true)}
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                <FaEye /> &nbsp; View
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={() => setShow(false)} centered size="lg">
        <Modal.Body className="p-0">
          <Carousel style={{ width: "auto" }}>
            {props.demoImages &&
              props.demoImages.map((img, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={img}
                    alt={`Slide ${index}`}
                    style={{ 
                      maxWidth: "100%",
                      height: "500px", 
                      objectFit: "contain", 
                      backgroundColor: "#b562d6"
                     }}
                  />
                </Carousel.Item>
              ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MobileProjectCards;