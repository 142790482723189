import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import MyManager from "../../Assets/Projects/mymanager.png";
import squad from "../../Assets/Projects/Squad.png";
import Upmis from "../../Assets/Projects/upmis.webp";
import academy from "../../Assets/Projects/academy.png";
import { TbWorldWww } from "react-icons/tb";
import { FaMobileAlt } from "react-icons/fa";
import MobileProjectCards from "./MobileProjectCards";
import daalVaat1 from "../../Assets/Projects/01daalvaat.png";
import daalVaat2 from "../../Assets/Projects/02daalvaat.png";
import daalVaat3 from "../../Assets/Projects/03daalvaat.png";
import daalVaat4 from "../../Assets/Projects/04daalvaat.png";
import fairToss1 from "../../Assets/Projects/fairtoss.jpg";
import fairToss2 from "../../Assets/Projects/fairtoss2.jpg";
import fairToss3 from "../../Assets/Projects/fairtoss3.jpg";
import fairToss4 from "../../Assets/Projects/fairtoss4.jpg";
import jci1 from "../../Assets/Projects/jci1.jpg";
import jci2 from "../../Assets/Projects/jci2.jpg";
import jci3 from "../../Assets/Projects/jci3.jpg";
import jci4 from "../../Assets/Projects/jci4.jpg";

function Projects() {
  const [selectedNav, setSelectedNav] = React.useState("Web");
  const daalVaaat = [daalVaat1, daalVaat2, daalVaat3, daalVaat4];
  const fairToss = [fairToss1, fairToss2, fairToss3, fairToss4];
  const jci = [jci1, jci2, jci3, jci4];
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
          <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="secondary-navbar d-flex justify-content-center w-100">
            <div 
              onClick={() => setSelectedNav("Web")} 
              className={`text-light mx-3 ${selectedNav === "Web" ? "selected-nav" : ""}`} 
              style={{ cursor: "pointer", paddingBottom: "7px" }}
            >
              <TbWorldWww style={{ marginBottom: "2px" }} /> Web App
            </div>
            
            <div 
              onClick={() => setSelectedNav("Mobile")} 
              className={`text-light mx-3 ${selectedNav === "Mobile" ? "selected-nav" : ""}`} 
              style={{ cursor: "pointer", paddingBottom: "7px" }}
            >
              <FaMobileAlt style={{ marginBottom: "2px" }} /> Mobile App
            </div>
          </div>
        </div>
        {
          (selectedNav === "Web") && (
            <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
           <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MyManager}
              isBlog={false}
              title="MyManager"
              description="I contributed as a Full Stack Engineer to MyManager, an all-in-one business management platform. I was responsible for designing and implementing the platform’s architecture, developing key features such as lead management and automation tools, and optimizing performance. My work helped ensure a seamless user experience and integration with third-party services, making MyManager a robust solution for businesses looking to enhance productivity and streamline operations."
              
              demoLink="https://mymanager.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={squad}
              isBlog={false}
              title="Squad AI"
              description="Developed a task management software for teams thatintegrates AI capabilities for task assignment andmonitoring. The platform serves as an alternative to Slack,allowing users to efficiently allocate tasks, track progress,and assign different team members to tasks for streamlinedteam collaboration."
              
              demoLink="https://app.trysquad.today/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Upmis}
              isBlog={false}
              title="UPMIS"
              description="Created a software solution for the local government inBangladesh, facilitating efficient tax collection and management within unions. UPMIS streamlines the process of issuing trade licenses and various certificates, while also storing and organizing all relevant area statistics for comprehensive record-keeping and data analysis."
              
              demoLink="https://demo.upmis.online/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={academy}
              isBlog={false}
              title="Academy Pro"
              description="Designed an advanced learning management system (LMS)similar to Udemy, offering a comprehensive platform for online courses. AcademyPro provides users with the ability to take exams and track their growth and progress. This additional feature enhances the learning experience by enabling users to assess their performance and tailor their learning journey for continuous improvement"
              demoLink="https://academypro.live/"              
            />
          </Col>
        </Row>
          )
        }
        {
          selectedNav === "Mobile" && (
            <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
          <Col md={4} className="project-card">
            <MobileProjectCards
              imgPath={daalVaat1}
              isBlog={false}
              title="DaalVaat"
              description="I developed the React Native frontend and built backend APIs using Node.js and MongoDB, enabling smooth meal ordering, secure payments, and real-time order tracking. I also integrated push notifications and optimized database queries for fast performance, ensuring students have an effortless and reliable meal service experience."
              demoImages={daalVaaat}
            />
          </Col>
          <Col md={4} className="project-card">
            <MobileProjectCards
              imgPath={fairToss1}
              isBlog={false}
              title="FairToss"
              description="I designed and developed the React Native UI, ensuring an engaging and intuitive betting experience. I built backend services using Node.js and MongoDB, integrated real-time odds updates via WebSockets, and implemented secure payment processing. Additionally, I focused on user authentication, transaction management, and responsible gaming features."
              demoImages={fairToss}
            />
          </Col>
          <Col md={4} className="project-card">
            <MobileProjectCards
              imgPath={jci1}
              isBlog={false}
              title="JCI"
              description="I built the React Native app with features like messaging, video calls, shared calendars, and location sharing. I developed backend APIs using Express and MongoDB, integrated WebRTC for video calls, and implemented real-time messaging with WebSockets. My focus was on seamless family communication, security, and performance optimization."
              demoImages={jci}
            />
          </Col>
          </Row>
          )
        }
      </Container>
    </Container>
  );
}

export default Projects;
